import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TextVariantContext from '@aurora/shared-client/components/context/TextVariantContext';
import useDevice from '@aurora/shared-client/components/useDevice';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type {
  Board,
  MessageConstraints
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  SortDirection
} from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import {
  deepClone,
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useCallback, useContext, useEffect } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import MessageListWidgetHelper from '../../../helpers/messages/MessageHelper/MessageListWidgetHelper';
import { ItemType, MessageTimestamp, MessageViewVariant } from '../../../types/enums';
import type {
  MessageViewsForWidgetQuery,
  MessageViewsForWidgetQueryVariables,
  MessageViewsForWidgetFragment
} from '../../../types/graphql-types';
import PaneledItemList from '../../common/List/PaneledItemList/PaneledItemList';
import MessagesWidgetFilters from '../../common/MessagesWidgetFilters/MessagesWidgetFilters';
import type { WidgetFC } from '../../common/Widget/types';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useEntityViewQuery from '../../useEntityViewQuery';
import useQueryParamArrayForWidget from '../../useQueryParamArrayForWidget';
import useQueryParamValueForWidget from '../../useQueryParamValueForWidget';
import useTranslation from '../../useTranslation';
import MessageListTabs, {
  MessageListTabItem,
  messageListTabItemMap
} from '../MessageListTabs/MessageListTabs';
import messagesQuery from '../MessageViewsForWidget.query.graphql';
import type {
  MessageListWidgetExtendedProps,
  MessageSortByOption,
  MessageTabOptions
} from '../types';
import { WidgetType } from '../types';
import localStyles from './MessageListForNodeByTopContentWidget.module.pcss';
import PaneledItemListSubHeader from '../../common/List/PaneledItemListSubHeader/PaneledItemListSubHeader';
import type { ContextNodeFragment } from '@aurora/shared-generated/types/graphql-types';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { buildMessagesConstraintsForNodeById } from '@aurora/shared-client/helpers/nodes/NodeHelper';

const sortByFieldOptions: MessageSortByOption[] = [
  {
    key: 'trending',
    value: {
      popularity: {
        direction: SortDirection.Desc
      }
    }
  },
  {
    key: 'viewsCount',
    value: {
      viewsCount: {
        direction: SortDirection.Desc
      }
    }
  },
  {
    key: 'kudosSumWeight',
    value: {
      kudosSumWeight: {
        direction: SortDirection.Desc
      }
    }
  },
  {
    key: 'repliesCount',
    value: {
      repliesCount: {
        direction: SortDirection.Desc
      }
    }
  }
];

const tabOptions: MessageTabOptions = {
  default: {
    [MessageListTabItem.TRENDING]: false,
    [MessageListTabItem.MOST_VIEWED]: true,
    [MessageListTabItem.MOST_KUDOED]: false,
    [MessageListTabItem.MOST_REPLIES]: false
  },
  additional: {
    [MessageListTabItem.NEWEST]: true,
    [MessageListTabItem.MOST_RECENT_USER_CONTENT]: false,
    [MessageListTabItem.MOST_RECENT]: false,
    [MessageListTabItem.NO_REPLIES]: false,
    [MessageListTabItem.NO_SOLUTIONS]: false,
    [MessageListTabItem.SOLUTIONS]: false
  }
};

const tabs = {
  default: Object.keys(tabOptions.default),
  additional: Object.keys(tabOptions.additional)
};

function getDefaultProps(context: ContextNodeFragment): MessageListWidgetExtendedProps {
  return {
    style: 'list',
    viewVariant: {
      type: MessageViewVariant.INLINE,
      props: {
        ...MessageListWidgetHelper.getMessageViewInlineDefaults(),
        ...MessageListWidgetHelper.getMessageViewCommonDefaults()
      }
    },
    listVariant: {
      type: ListVariant.LIST_GROUP
    },
    pagerVariant: {
      type: PagerVariant.LOAD_MORE
    },
    pageSize: 10,
    useTitle: true,
    hideIfEmpty: true,
    pagerOption: true,
    pagerOptionCard: false,
    panelType: PanelType.DIVIDER,
    sortByFieldValues: sortByFieldOptions,
    conversationStyle: null,
    showTabs: false,
    tabItemMap: tabOptions,
    nodeScopeId: context.id
  };
}
export function getFinalProps(
  props: MessageListWidgetExtendedProps,
  hasContextUser: boolean,
  nodeContext: ContextNodeFragment
): MessageListWidgetExtendedProps {
  const defaultProps = getDefaultProps(nodeContext);
  if (hasContextUser && defaultProps?.tabItemMap) {
    defaultProps.tabItemMap.default[MessageListTabItem.MOST_KUDOED] = true;
  }
  const finalProps: MessageListWidgetExtendedProps = merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });

  if (!finalProps.sorts) {
    finalProps.sorts = hasContextUser
      ? {
          kudosSumWeight: {
            direction: SortDirection.Desc
          }
        }
      : {
          viewsCount: {
            direction: SortDirection.Desc
          }
        };
  } else {
    if (finalProps.sorts?.popularity && finalProps.conversationStyle !== ConversationStyle.Idea) {
      finalProps.sorts = {
        viewsCount: {
          direction: SortDirection.Desc
        }
      };
    }
  }
  return finalProps;
}

/**
 * A node widget that displays messages of a given node focusing on Top content.
 *
 * @author Sravan Reddy
 */
const MessageListForNodeByTopContentWidget: WidgetFC<MessageListWidgetExtendedProps> = props => {
  const { isVisible, ...rest } = props;
  const { contextNode, contextUser, authUser } = useContext(AppContext);
  const finalProps = getFinalProps(rest, !!contextUser, contextNode);
  const {
    sorts,
    style,
    titleContextVariant,
    viewVariant,
    listVariant,
    pagerVariant,
    className,
    pageSize,
    useTitle,
    hideIfEmpty,
    panelType,
    sortByFieldValues,
    conversationStyle,
    showTabs,
    addTags,
    tabItemMap,
    nodeScopeId
  } = finalProps;

  const cx = useClassNameMapper(localStyles);

  const textVariantContext = useContext(TextVariantContext);
  const user = contextUser ?? authUser;
  const tenantContext = useContext(TenantContext);

  const { key: tagsParameterKey, value: tagsParameterValue } = useQueryParamArrayForWidget(
    EndUserQueryParams.SEARCH_BY_TAGS_FILTER,
    []
  );

  let constraints: MessageConstraints = buildMessagesConstraintsForNodeById(
    tenantContext,
    nodeScopeId,
    tagsParameterValue
  );
  const { isAnonymous } = useRegistrationStatus();

  const defaultTabs = {};
  const additionalTabs = {};
  tabs.default.forEach(value => {
    defaultTabs[value] = tabItemMap.default[value];
  });
  tabs.additional.forEach(value => {
    additionalTabs[value] = tabItemMap.additional[value];
  });
  const tabItemOptions = { default: defaultTabs, additional: additionalTabs };
  const { isMobile } = useDevice();

  if (contextUser) {
    constraints.authorId = { eq: user.id };
  } else {
    constraints.depth = { eq: 0 };
  }

  let contextBoard;
  const adjustedViewVariant = deepClone(viewVariant);
  if (conversationStyle) {
    constraints.conversationStyle = { eq: getAsEnum(conversationStyle, ConversationStyle) };
  } else if (contextNode?.nodeType === NodeType.BOARD) {
    viewVariant.props.useNodeHoverCard = false;
    contextBoard = contextNode as Board;
    constraints.conversationStyle = { eq: contextBoard.conversationStyle };
  }

  const configProps: MessageListWidgetExtendedProps = {
    sorts: sorts,
    style,
    viewVariant: adjustedViewVariant,
    listVariant,
    pagerVariant,
    className,
    pageSize,
    useTitle,
    hideIfEmpty,
    panelType,
    sortByFieldValues,
    conversationStyle,
    showTabs,
    addTags,
    tabItemMap: tabItemOptions,
    titleContextVariant
  };
  const messageWidgetConfigHelper = new MessageListWidgetHelper(
    configProps,
    contextBoard,
    contextUser,
    WidgetType.TOP,
    null,
    false,
    contextNode
  );

  const tabList = messageWidgetConfigHelper.fetchTabs();
  let [firstTab] = tabList;

  if (firstTab === undefined && addTags) {
    firstTab = 'tagsFilter' as MessageListTabItem;
  }

  const { key: queryParamKey, value: queryParamValue } = useQueryParamValueForWidget(
    'tab',
    firstTab
  );
  const activeTab = getAsEnum<typeof MessageListTabItem>(
    queryParamValue,
    MessageListTabItem,
    firstTab
  );

  let sortForWidget = sorts;

  if (showTabs && activeTab) {
    const selectedTab = messageListTabItemMap[activeTab];
    if (selectedTab && activeTab === MessageListTabItem.SOLUTIONS) {
      if (contextUser) {
        constraints.solution = { eq: true };
      } else {
        constraints.conversationSolved = { eq: true };
      }
    }
    sortForWidget = selectedTab.sorts;
    constraints = { ...constraints, ...selectedTab.constraints };
    if (adjustedViewVariant.props.timeStampType) {
      if (sortForWidget.conversationLastPostingActivityTime) {
        adjustedViewVariant.props.timeStampType = MessageTimestamp.LAST_POSTING_ACTIVITY_TIME;
      } else if (sortForWidget.lastPublishTime) {
        adjustedViewVariant.props.timeStampType = MessageTimestamp.LAST_PUBLISHED_TIME;
      } else {
        adjustedViewVariant.props.timeStampType = MessageTimestamp.POST_TIME;
      }
    }
  }

  const variables = {
    constraints,
    sorts: sortForWidget,
    first: pageSize,
    useUnreadCount: !isAnonymous
  };

  const queryResult = useEntityViewQuery<
    ItemType.MESSAGE,
    MessageViewsForWidgetQuery,
    MessageViewsForWidgetQueryVariables
  >(module, ItemType.MESSAGE, adjustedViewVariant, messagesQuery, {
    variables
  });

  const showEmptyState = !hideIfEmpty || tabList.length > 1;
  const { data, loading } = queryResult;

  useEffect(() => {
    if (!showEmptyState && !loading) {
      isVisible(data?.messages?.edges?.length !== 0);
    }
  }, [data, isVisible, loading, showEmptyState]);

  const localTextVariantContext = MessageListWidgetHelper.getTextVariantForMessageQueryVariables(
    variables,
    textVariantContext,
    user
  );

  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_LIST_FOR_NODE_BY_TOP_CONTENT_WIDGET,
    false,
    localTextVariantContext
  );

  const title = formatMessage('title');

  /**
   * A callback function to render Empty State when entity list has no messages
   */
  const renderNoPosts = useCallback((): React.ReactElement => {
    return (
      <EmptyState description={formatMessage('emptyDescription')} alignment={TextAlignment.LEFT} />
    );
  }, [formatMessage]);

  if (textLoading) {
    return null;
  }

  /**
   * A Predicate to ensure the view variant type.
   */
  function isInline(
    messageViewVariant: ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>
  ): messageViewVariant is ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant.INLINE> {
    return messageViewVariant.type === MessageViewVariant.INLINE;
  }

  // to force hide/show the elements in compact view.
  if (isInline(adjustedViewVariant)) {
    if (style === 'compact') {
      adjustedViewVariant.props.useBody = false;
      adjustedViewVariant.props.useCompactSpacing = true;
      adjustedViewVariant.props.useAuthorRank = false;
      adjustedViewVariant.props.useKudosCount = false;
      adjustedViewVariant.props.usePreviewMedia = false;
      adjustedViewVariant.props.useMedia = false;
      adjustedViewVariant.props.useTags = false;
      adjustedViewVariant.props.useRepliesCount = false;
      adjustedViewVariant.props.useNode = false;
      adjustedViewVariant.props.useTimeToRead = false;
      adjustedViewVariant.props.useUnreadCount = false;
      adjustedViewVariant.props.useViewCount = false;
      adjustedViewVariant.props.avatarSize = IconSize.PX_40;
      adjustedViewVariant.props.boardIconSize = IconSize.PX_24;
      adjustedViewVariant.props.renderPostTimeBeforeAuthor = true;
    } else {
      adjustedViewVariant.props.renderPostTimeBeforeAuthor = false;
    }
  }

  if (isMobile) {
    adjustedViewVariant.props.boardIconSize = IconSize.PX_24;
  }

  function renderHeader(): React.ReactElement {
    if (useTitle) {
      return <h3 className={cx('text-break h5 mb-0')}>{title}</h3>;
    }
  }

  /**
   * Function to render tabbed filters
   */
  function renderTabList(): React.ReactElement {
    return (
      <PaneledItemListSubHeader panel={panelType} as={'nav'}>
        <MessageListTabs
          activeTab={activeTab}
          items={tabList}
          queryParamKey={queryParamKey}
          recordHistory={false}
          conversationStyle={configProps.conversationStyle as ConversationStyle}
          useBottomBorder={panelType === PanelType.STANDARD}
        />
      </PaneledItemListSubHeader>
    );
  }

  /**
   * Function to render filters
   */
  const RenderFilterBar: React.FC = () => {
    return (
      <PaneledItemListSubHeader
        panel={panelType}
        as={'nav'}
        className={cx({ 'lia-bubble': panelType === PanelType.BUBBLE })}
      >
        <MessagesWidgetFilters
          tagsParameterKey={tagsParameterKey}
          selectedTags={tagsParameterValue}
          nodeId={nodeScopeId}
          onChange={() => {}}
          className={cx(
            { 'lia-g-py-10': panelType !== PanelType.BUBBLE },
            { 'lia-g-pt-0': panelType === PanelType.BUBBLE }
          )}
        />
      </PaneledItemListSubHeader>
    );
  };

  /**
   * Function to render filters and sorts tabs list
   */
  const RenderSubHeaders: React.FC = () => {
    return (
      <>
        {showTabs && renderTabList()}
        {addTags && style === 'list' && <RenderFilterBar />}
      </>
    );
  };

  return (
    <TextVariantContext.Provider value={localTextVariantContext}>
      <EditableWidget<MessageListWidgetExtendedProps> props={finalProps}>
        <PaneledItemList<
          MessageViewsForWidgetFragment,
          ItemType.MESSAGE,
          ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>,
          MessageViewsForWidgetQuery,
          MessageViewsForWidgetQueryVariables
        >
          type={ItemType.MESSAGE}
          variant={adjustedViewVariant}
          listVariant={listVariant}
          queryResult={queryResult}
          panel={panelType}
          pagerVariant={pagerVariant}
          pageSize={pageSize}
          header={renderHeader}
          subHeader={RenderSubHeaders}
          bodyClassName="lia-g-mb-0"
          className={cx(className, 'lia-message-widget-panel')}
          empty={renderNoPosts}
          useEmpty={showEmptyState}
          footerClassName={cx({ 'lia-message-widget-footer': style === 'card' })}
          useSubHeaderWhenEmpty={
            activeTab && (queryResult.data?.messages?.edges?.length > 0 || showEmptyState)
          }
          useFooter={pagerVariant.type !== PagerVariant.NONE}
          id="MessageListForNodeByTopContentWidget"
          role="tabpanel"
        />
      </EditableWidget>
    </TextVariantContext.Provider>
  );
};

export default MessageListForNodeByTopContentWidget;
